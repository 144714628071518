<template>
    <CommonReply/>
</template>

<script>
    import CommonReply from "@/components/common/CommonReply.vue";

    export default {
        name: "Reply",
        components: {
            CommonReply
        }
    }
</script>

<style scoped>

</style>
